.appointment_details_container {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.payment_success_container {
  border-radius: 8px;
  border: 1px solid #9cbebe;
  /* border-bottom: 1px solid #9cbebe; */
  padding: 1rem 0 1.5rem;
}

.success_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.success_icon {
  min-height: 45px;
  min-width: 45px;
}

.success_message_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem !important;
}

.success_message {
  color: #2a2f45;
  font-size: 20px;
  font-weight: 600;
}

.apmt_details_container {
  padding: 10px 0;
}

.apmt_details {
  color: #4f566b;
  font-weight: 400;
  font-size: 20px;
}

.apmt_details_dark {
  font-weight: 600;
}

.details_heading {
  padding: 0.5rem 0;
}

.details_heading_text {
  font-size: 18px;
  color: #4f566b;
}

.details_box {
  background-color: #e0f0f2;
  border-radius: 8px;
  height: 250px;
  width: 100%;
}

.appt_details_box_content {
  padding: 1.5rem;
}

.appt_text_dark {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  /* padding-bottom: 5px; */
}

.appt_text_light {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.apt_address_content {
  font-size: 16px;
  line-height: 20px;
  color: #3c4257;
  padding-left: 75px;
}

.add_google_button_container {
  padding: 1.8rem 0 1rem 0;
}

.add_google_button {
  color: white !important;
  width: 100%;
}

.add_outlook_button {
  width: 100%;
}

.calendar_options_container {
  padding: 1rem;
}

.calendar_option {
  padding: 1rem;
}

.calendar_link {
  color: #2a2f45;
  text-decoration: none !important;
}
