.dob_textbox .MuiInput-root {
  position: relative;
  border: 0.2px solid #DCDCDC;
  border-radius: 3px;
  padding: 10px;
  /* margin-top: 25px; */
  /* text-decoration: none; */
}

/* .dob_textbox .MuiInput-root::before {
  border-color: lightgray;
}

.dob_textbox .MuiInput-root::after {
  border-color: #0D9797;
} */

.dob_label {
  font-size: 14px;
  color: #8792A2;
  padding-bottom: 0.6rem !important;
}

/* .dob_textbox .MuiInput-underline {
  border-bottom: 0 solid white !important;
  text-decoration: none !important;
} */
