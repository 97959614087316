:root {
  --white: #fff;
  --black: #000;
  --charcoal-black: #4a4b4e;
  --error-div-red: #ff000ca6;
  --error-message-red: #dc121cfa;
  --error-main-red: #ff5960;
  --field-title-grey: #757575;
}

/* .StripePaymentSection {
  font-family: "Inter", sans-serif !important;
} */

.stripe_container {
  padding: 2rem;
}

/* .CardBrandIcon-container {
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  width: 1.5em;
  -webkit-perspective: 1000px;
  perspective: 1000px;
} */

/* .CardBrandIcon-container {
  position: relative;
} */

#cardInfoField {
  position: relative;
}

.cardIconSet {
  position: absolute;
  top: 5px;
  right: 5px;
}

#cvcInfoField {
  position: relative;
}

.cvcframe {
  position: absolute;
  top: 5px;
  right: 5px;
}

.payTitle {
  font-size: 21px !important;
  color: #4f566b;
  padding: 1.5rem 0;
  font-weight: 600;
}

.borderfield {
  border: none;
  outline: none;
  font-size: 13px;
  width: 100%;
  padding-top: 3px;
  color: var(--black);
  background-color: var(--white);
}

.state_option {
  font-size: 11px;
}

.submit_btn_container {
  padding-top: 1rem;
}

.SubmitButton {
  /* align-content: center; */
  padding: 10px 52px;
  font-size: 1em;
  display: block;
  margin: 0 auto;
  /* background-color: var(--charcoal-black); */
  background-color: #14a6a6;
  color: var(--white);
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.fieldWrapper {
  padding: 6px 0;
}

.FieldTitle {
  color: var(--field-title-grey);
  padding-bottom: 5px;
  padding-top: 12px;
  font-size: 13px;
  /* margin-top: 20px; */
}

.error-div {
  width: 100%;
  padding-top: 15px;
  min-height: 60px;
  height: auto;
  background-color: var(--error-div-red);
  border-radius: 10px;
  text-align: center;
  margin: 10px 0px 10px 0px;
  color: var(--white);
}
.ErrorMessage {
  color: var(--error-message-red);
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  padding-top: 4px;
  animation: slowanimate 0.5s ease;
}

.sub-button {
  background-color: green;
}

.divide-div {
  display: flex;
}

.expfield {
  width: 50%;
  padding: 10px;
}
.cvcfield {
  flex: 1;
  padding: 10px;
  border-left: 1px solid #00000047;
}
.cardfield {
  padding: 10px;
  border-bottom: 1px solid #00000047;
}

.fielditem {
  padding: 8px;
}

.fielditem-zip {
  padding: 8px;
  border-top: 1px solid #00000047;
}

.group {
  outline: none;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  transition: 0.2s ease all;
}
.group:focus-within {
  box-shadow: 0 0 1px 2px rgb(12 150 243 / 34%);
  outline: none;
  border: 1px solid #d6d6d6;
}

.groupfocus {
  outline: none;
  box-shadow: 0 0 1px 3px rgb(12 150 243 / 34%);
  border-radius: 5px;
  transition: 0.3s ease all;
}
.errorgroup {
  outline: none;
  box-shadow: 0 0 1px 1px #ff000ca6;
  border-radius: 2px;
}

.field {
  background: transparent;
  font-weight: 300;
  border: none;
  color: #000;
  outline: none;
  flex: 1;
  padding-right: 10px;
  padding-left: 10px;
  cursor: text;
  width: 100%;
}
.card-error {
  padding: 10px;
  border: 1px solid var(--error-main-red);
  border-radius: 5px 5px 0 0;
}
.date-error {
  width: 50%;
  padding: 10px;
  border: 1px solid var(--error-main-red);
  border-radius: 0 0 0 5px;
}
.cvc-error {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--error-main-red);
  border-radius: 0 0 5px 0;
}
.zip-error {
  padding: 8px;
  border: 1px solid var(--error-main-red);
  border-radius: 0 0 5px 5px;
}

/* animations */
@keyframes slowanimate {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(1px);
  }
}

.sub-buttonz {
  opacity: 0.5;
}

.payment_prev_btn_container {
  padding-top: 1rem;
}

.pay_previous_button {
  width: 100%;
}
