.insurance_details_container {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.apmt_details_container {
  padding: 10px 0;
}

.apmt_details {
  color: #4f566b;
  font-weight: 400;
  font-size: 20px;
}

.apmt_details_dark {
  font-weight: 600;
}

.details_heading {
  padding: 0.5rem 0;
}

.details_heading_text {
  font-size: 18px;
  color: #4f566b;
}

.prime_ins_container {
  padding-bottom: 1rem !important;
}

.prime_ins_box {
  border: 1px solid #139696;
  border-radius: 8px;
  height: 160px;
  width: 100%;
}

.prime_ins_box_content {
  padding: 1.3rem;
}

.add_sec_ins_btn {
  width: 100%;
}

.sec_ins_box {
  /* background-color: #e0f0f2; */
  border: 1px solid #139696;
  border-radius: 8px;
  height: 175px;
  width: 100%;
}

.sec_ins_box_content {
  padding: 1.3rem;
}

.insurance_heading {
  font-size: 21px;
  font-weight: 600;
  /* line-height: 36px; */
  padding-bottom: 8px;
}

.insurance_name {
  font-size: 18px;
  line-height: 20px;
  color: #139696;
  padding-bottom: 8px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.insurance_content {
  font-size: 16px;
  line-height: 21px;
  /* color: #3c4257; */
}

.edit_button_container {
  padding: 1rem 0;
}

.edit_button {
  width: 100%;
}

.next_button_container {
  padding: 1rem 0;
}

.next_button {
  width: 100%;
  color: white !important;
}

.edit_prime_ins_container {
  padding-bottom: 1rem !important;
}

.edit_prime_details_box {
  border: 1px solid #139696;
  border-radius: 8px;
  height: 420px;
  width: 100%;
}

.edit_prime_holder_box {
  border: 1px solid #139696;
  border-radius: 8px;
  height: 600px;
  width: 100%;
  /* padding-top: 1rem; */
}

.edit_prime_holder_box_errors {
  border: 1px solid #139696;
  border-radius: 8px;
  height: 640px;
  width: 100%;
  /* padding-top: 1rem; */
}

.edit_sec_details_box {
  /* background-color: #e0f0f2; */
  border: 1px solid #139696;
  border-radius: 8px;
  height: 420px;
  width: 100%;
}

.edit_sec_holder_box {
  /* background-color: #e0f0f2; */
  border: 1px solid #139696;
  border-radius: 8px;
  height: 600px;
  width: 100%;
}

.edit_sec_holder_box_errors {
  /* background-color: #e0f0f2; */
  border: 1px solid #139696;
  border-radius: 8px;
  height: 640px;
  width: 100%;
}

.edit_details_box_content {
  padding: 1rem 1.2rem 0;
}

/* 
.edit_holder_box_content {
  padding: 1rem 1.2rem 0;
} */

.ins_heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  padding-top: 16px !important;
}

.edit_details_heading {
  padding-bottom: 1rem !important;
}

.edit_details_heading_text {
  font-size: 18px;
  color: #4f566b;
}

.confirm_button_container {
  padding: 0.8 0 !important;
}

.confirm_button {
  width: 100%;
  color: white !important;
}

.cancel_button_container {
  padding: 0.8rem 0 !important;
}

.cancel_button {
  width: 100%;
}

.button_grid {
  display: flex;
  justify-content: space-between;
  /* gap: 0.5rem; */
}

.cashPatient_no_insurance_container {
  padding: 2rem 0 2rem 0;
}

.cashPatient_no_insurance {
  font-size: 18px;
  color: #4f566b;
}

/* .test_inurance_dropdown {
  max-width: 150px;
} */

/* .test_inurance_dropdown .MuiAutocomplete-option {
  width: auto !important;
  background-color: aquamarine !important;
} */

.MuiAutocomplete-listbox {
  /* min-width: 100px !important; */
  /* white-space: nowrap; */
}
