.personal_details_container {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.personal_details_container .apmt_details_container {
  padding: 16px 0;
}

.apmt_details_clinicName {
  color: #4f566b;
  font-weight: 600;
  font-size: 20px;
  padding: 2px 0;
}

.apmt_details_line2 {
  color: #4f566b;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 3px;
}

.apmt_details_line3 {
  color: #4f566b;
  font-weight: 600;
  font-size: 16px;
}

.details_heading {
  padding: 0.5rem 0;
}

.details_heading_text {
  font-size: 18px;
  color: #4f566b;
}

.personal_details_box {
  background-color: hsl(187, 41%, 91%);
  border-radius: 8px;
  height: 170px;
  width: 100%;
}

.details_box_content {
  padding: 2rem;
}

.patient_name {
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  padding-bottom: 0.5rem;
}

.address_heading {
  font-size: 16px;
  color: #697386;
  line-height: 20px;
  padding-bottom: 5px;
}

.address_content {
  font-size: 16px;
  line-height: 20px;
  color: #3c4257;
}

.edit_button_container {
  padding: 1rem 0 !important;
}

.edit_button {
  width: 100%;
}

.next_button_container {
  padding: 1rem 0 !important;
}

.next_button {
  width: 100%;
  color: white !important;
}

.edit_details_box {
  background-color: #e0f0f2;
  border-radius: 8px;
  height: 310px;
  width: 100%;
}

.edit_details_box_content {
  padding: 0 1.2rem;
}

.edit_details_heading {
  padding-bottom: 1rem !important;
}

.edit_details_heading_text {
  font-size: 18px;
  color: #4f566b;
}

.confirm_button_container {
  padding: 0.8rem 0 !important;
}

.confirm_button {
  width: 100%;
  color: white !important;
}

.cancel_button_container {
  padding: 0.8rem 0 !important;
}

.cancel_button {
  width: 100%;
}
